html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('assets/open-sans-v17-latin-300.woff2') format('woff2'),
    url('assets/open-sans-v17-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('assets/open-sans-v17-latin-regular.woff2') format('woff2'),
    url('assets/open-sans-v17-latin-regular.woff') format('woff');
}

.ReactVirtualized__Table__headerTruncatedText {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  min-width: 0px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  transform: translateY(25%);
  fill: currentColor;
}

.rsw_3G {
  z-index: 0 !important;
}
