.rsw_2Y {
  flex: auto;
  display: flex;
}

.rsw_2f {
  display: flex;
}

.rsw_3G {
  z-index: 0;
}
